:root {
  --bs-mainColor: #c356b3;
  --bs-secondColor: #00b6b6;
  --Dark-Gray: #6a707c;
  --Light-Gray: #8391a1;
  --White: #fff;
  --Black: #000;
  /* --bs-primary: #b99e6e2e; */
}

* {
  font-family: "Urbanist";
}

body {
  margin: 0px;
  font-family: "Urbanist";
}

.vh-75 {
  height: 75%;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-28 {
  font-size: 28px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.text-muted {
  color: #6a707c !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.menuIcon {
  height: 20px;
  width: 20px;
}

/* ------dropdown active---- */
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-primaryColor);
  text-decoration: none;
  background-color: white;
  font-weight: 900;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: none !important;
}

@media only screen and (max-width: 2560px) and (min-width: 1024px) {
  .toggle {
    display: none;
  }
}

.login-logo {
  width: 238px;
  height: 151px;
  flex-shrink: 0;
}

.login-title {
  color: var(--Dark, #1e232c);
  text-align: center;
  font-family: "Urbanist";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  /* 32.5px */
  letter-spacing: -0.25px;
}

.btn-login {
  background-color: var(--bs-mainColor) !important;
  width: 487.546px;
  height: 56.289px;
  color: #fff;
  text-align: center;
  font-family: "Urbanist";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 8.031px;
}

.login-input {
  border-radius: 8.031px;
  border: 1.004px solid #e8ecf4;
  background: #f7f8f9;
}

.input-bg {
  background: #f7f8f9;
}

.login-box {
  padding: 20px;
  /* width: 568px; */
  /* height: 581px; */
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px solid #bebebe;
  background: #fff;
}

.reSendOtp {
  color: var(--bs-mainColor);
  background-color: #fff;
  /* cursor: pointer; */
  font-weight: bolder;
  /* font-size: 15px; */
}

.otpTimer {
  color: var(--bs-mainColor);
  text-align: center;
  font-family: "Urbanist";
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  /* 32.5px */
  letter-spacing: -0.25px;
}

.sidebar-logo-sm {
  width: 100px;
  height: 80px;
}

.sidebar-logo {
  width: 187px;
  height: 119px;
}

.sidebar-menu-list li {
  list-style-type: none;
  /* margin-bottom: 25px;
  margin-top: 25px; */
}

.sidebar-menu-list li a {
  text-decoration: none;
  margin: 0;
  font-size: 18px;
  font-style: normal;
  color: #5b5b5b;
  font-weight: 600;
  font-family: "Urbanist";
  padding: 10px 0px !important;
}

.sidebar-menu-list li a.active {
  color: var(--bs-mainColor) !important;
}

.sidebar-menu-list li a.active path {
  fill: var(--bs-mainColor);
}

.sidebar-menu-list li img {
  width: 25px;
  height: 25px;
}

.sidebar-menu-list li svg {
  width: 25px;
  height: 25px;
}

.title {
  color: #000;
  font-family: "Urbanist";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.content {
  /* width: 262px; */
  height: 98px;
  border-radius: 5.541px;
}

.content .firsttext {
  color: #4f0744;
  font-family: "Urbanist";
  font-size: 18.841px;
  font-weight: 600;
}

.content .secondtext {
  color: #000;
  font-family: "Urbanist";
  font-size: 33.248px;
  font-weight: 700;
}

.cardHeading {
  color: #000;
  font-family: "Urbanist";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.latest-learning {
  height: 217px !important;
  border-radius: 9px !important;
  overflow: hidden;
  position: relative !important;
}

.text-box {
  background: linear-gradient(0deg, #858585 0%, rgba(0, 0, 0, 0) 100%);
  height: 115px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  padding-left: 20px;
  white-space: nowrap;
  max-height: 100%;
  /* overflow: hidden; */
}

.text-box h3 {
  font-size: 23px;
  font-weight: 700;
  color: #fff;
  margin: 0px;
  padding-top: 40px;
}

.text-box p {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
}

.user-layout {
  /* width: 446px; */
  /* height: 85px; */
  border-radius: 6px;
  border: 1px solid #d8d8d8;
  background: #f5f5f5;
}

.recent-user {
  width: 59px;
  height: 59px;
  border-radius: 155.869px;
  object-fit: cover;
}

.userText {
  margin-left: 8px;
  font-family: "Urbanist";
  font-size: 19px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.dropdown-menu-home {
  position: absolute;
  right: 52px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 227px;
}

.dropdown-menu-home li {
  padding: 5px;
  color: #000;
  text-align: center;
  font-family: "Urbanist";
  font-size: 19px;
  font-weight: 500;
  list-style: none;
}

.dropdown-menu-home button:hover {
  background-color: #e0e0e0;
}

.dropdown-menu:before {
  position: absolute;
  top: -11px;
  right: 100px;
  display: inline-block;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

.dropdown-menu:after {
  position: absolute;
  top: -10px;
  right: 100px;
  display: inline-block;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  content: "";
}

.dropdown-menu-home.pull-right:after {
  right: 10px;
  left: auto;
}

.tableHeader {
  background: rgba(171, 77, 157, 0.15);
  color: #680c5b;
  font-family: "Urbanist";
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 36.547px;
  /* 192.352% */
}

.tableBody {
  color: #000;
  font-family: "Urbanist";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 36.547px;
  /* 182.735% */
}

.userTable .form-check-input:focus {
  box-shadow: none;
}

.userTable .form-check-input:checked {
  background-color: #7ed360;
  border-color: #c5c5c5;
  box-shadow: none;
}

.userTable .form-check-input {
  background-color: #d36060;
  border-color: #c5c5c5;
}

.form-switch .form-check-input {
  width: 49px;
  height: 18px;
  flex-shrink: 0;
  margin-left: -2.5em;
  background-image: url(../public/images/svg/round.svg);
}

.table-pagination .page-item {
  padding: 5px;
}

.table-pagination .page-item .page-link {
  border-radius: 10px;
  padding: 13px 20px;
  border: 0.667px solid #cbcbcb;
  color: #000;
  font-size: 13.62px;
  font-weight: 500;
}

.active>.page-link,
.page-link.active {
  color: #fff !important;
  background-color: var(--bs-mainColor);
  border-color: var(--bs-mainColor) !important;
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
  .table-pagination .page-item .page-link {
    padding: 7px 14px;
  }
}

.table-pagination .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: var(--bs-secondColor) !important;
  border-color: var(--bs-secondColor) !important;
}

.table-pagination .page-item1 .page-link {
  padding: 13px 20px;
  border: 0px;
  color: #000;
  font-size: 13.62px;
  font-weight: 500;
}

.page-link {
  display: inline;
}

.page-link:focus {
  z-index: 3;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: none;
}

.upload__btn {
  text-align: center;
  padding: 0px 30px;
  cursor: pointer;
  border: 1.004px dashed #b3b3b3;
  border-radius: 8.031px;
  color: var(--Light-Gray);
  font-family: "Urbanist";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}

.upload__btn p,
.upload__btn span {
  color: var(--Light-Gray);
  font-family: "Urbanist";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 27.5px */
}

.upload_inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

/* .upload__inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
} */

.modelBtn {
  background-color: var(--bs-mainColor) !important;
  width: 488px;
  height: 60px;
  color: #fff;
  text-align: center;
  font-family: "Urbanist";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 8.031px;
}

.modelBtn:hover {
  background: #c356b3;
}

.info-modal .modal-title {
  color: #000;
  font-family: "Urbanist";
  font-size: 33px;
  font-weight: 700;
  max-width: 404px;
  margin: auto;
  width: 100%;
}

.modal-main .btn-close {
  position: absolute;
  top: 47px;
  right: 30px;
}

.info-modal label {
  color: #000;
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}

.info-modal input,
.info-modal textarea {
  /* color: var(--Light-Gray); */
  color: #000;
  font-family: "Urbanist";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 27.5px */
  border-radius: 8.031px;
  border: 1.004px solid var(--Border, #e8ecf4);
  background: #f7f8f9;
}

.info-modal .btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url(../public/images/svg/close.svg) no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 1;
}

/* .lectureDiv {
  height: 351px !important;
  border-radius: 9px !important;
  overflow: hidden;
  position: relative !important;
} */
/* 
.lectureView {
  height: 345px !important;
  border-radius: 9px !important;
  overflow: hidden;
  position: relative !important;
} */

.lectureView {
  height: 345px !important;
  border-radius: 9px !important;
  overflow: hidden;
  position: relative !important;
}

.lectureBtn {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* text-align: center; */
  align-items: flex-end;
  margin: 0px;
  padding-bottom: 20px;
}

/* .lectureBtn {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin: 0px;
  padding-top: 70px;
} */

.lecture-box {
  background: linear-gradient(0deg, #505050 0%, rgba(0, 0, 0, 0) 100%);
  height: 115px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
}

/* .lecture-box {
  background: linear-gradient(0deg, #505050 0%, rgba(0, 0, 0, 0) 100%);
  height: 115px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  padding-left: 20px;
  overflow: hidden;
} */
.lectureIcon {
  display: flex;
  justify-content: flex-end;
  /* text-align: end; */
  align-items: center;
  /* padding-right: 20px; */
}

/* .lectureIcon {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
} */

/* .lectureText {
  color: #fff;
  font-size: 23px;
  font-weight: 700;
  line-height: 125%;
  white-space: nowrap;
  max-height: 100%; 
  overflow: hidden; 
} */

/* .lectureText {
  color: #fff;
  font-size: 23px;
  font-weight: 700;
  line-height: 125%;
  white-space: nowrap;
  max-height: 100%;
  overflow: hidden;
} */

.lectureText {
  color: #fff;
  font-size: 23px;
  font-weight: 700;
  line-height: 35px;
  /* white-space: nowrap; */
  max-height: 100%;
  /* overflow: hidden; */
  /* display: -webkit-box; */
  /* -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.lectureDescription {
  width: 66%;
  color: #ebe7e7;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  max-height: 100%;
}

.firstWeekHeading {
  color: #000;
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
}

.secondWeekHeading {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: normal;
  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
}

.thirdWeekHeading {
  width: 80%;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
}

.ctmTable .table>tbody {
  vertical-align: middle;
}

.userDetailsLabel {
  color: #000;
  font-size: 18.300px;
  font-style: normal;
  font-weight: 700;
  line-height: 210%;
  /* 27.5px */
}

.userDetailsText {
  padding-left: 15px;
  color: #555;
  font-size: 18.300px;
  font-style: normal;
  font-weight: 500;
}
.userDetailsText img {
  height: auto;
  width: 100%;
}

.modalDeleteBtn {
  width: 231px;
  height: 60px;
  border-radius: 8.031px;
  background: #c356b3;
  color: var(--White);
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  border-color: #c356b3;
}

.modalCancelBtn {
  height: 60px;
  border-radius: 8.031px;
  background: rgba(195, 86, 179, 0.12);
  color: #000;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  border-color: rgba(195, 86, 179, 0.12);
}

.info-modal .form-select {
  color: var(--Light-Gray);
  font-family: "Urbanist";
  font-size: 22px;
  font-weight: 500;
  border-radius: 8.031px;
  border: 1.004px solid var(--Border, #e8ecf4);
  background-color: #f7f8f9;
}

.channelUser {
  border-radius: 50%;
  width: 37px;
  height: 37px;
  object-fit: cover;
}

.channelHead {
  color: #000;
  font-family: Urbanist;
  font-size: 14px;
  font-weight: 600;
  /* line-height: 125%; */
}

.channelText {
  color: #a2a2a2;
  font-family: Urbanist;
  font-size: 14px;
  font-weight: 500;
  line-height: inherit;
  text-transform: capitalize;
}

.msgBox {
  border-bottom: 1px solid #e8e8ea;
}

.msguser {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.msgHead {
  color: #000;
  font-family: Urbanist;
  font-size: 16px;
  font-weight: 600;
}

.msgText {
  color: #000;
  font-family: Urbanist;
  font-size: 15px;
  font-weight: 500;

}

.msgreplyIcon {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  object-fit: cover;
}

.msgreplyText {
  color: #000;
  font-family: Urbanist;
  font-size: 12.6px;
  font-weight: 500;
}

.msgView1 {
  height: 49px;
  background: #61cbcb;
}

.msgView {
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  left: 0px;
  right: 0px;
  height: 49px;
  background-color: #61cbcb;
  /* Set your desired background color */
  padding: 10px;
  /* Adjust padding as needed */
}

.msgView1 {
  height: 49px;
  background-color: #61cbcb;
  /* Set your desired background color */
  padding: 10px;
  /* Adjust padding as needed */
}

.communityPage .nav-pills .nav-link.active,
.communityPage .nav-pills .show>.nav-link {
  color: black;
  /* background-color: #0d6efd; */
  border-bottom: 2px solid #ab4d9d;
}

.communityPage .nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0;
}

.communityPage .nav-link:focus,
.nav-link:hover {
  color: black;
}

.communityPage .nav-link {
  color: #000;
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.edit-user {
  width: 260px;
  height: 164px;
  overflow: hidden;
  border-radius: 13.819px;
  /* background: #C4C4C4; */
}

.wordBreak {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wordBreak1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.foodLayout {
  border-radius: 6px;
  border: 1px solid #d8d8d8;
}

.foodImg {
  width: 90px;
  height: 90px;
}

.profileEdit {
  width: 133.372px;
  height: 47.326px;
  flex-shrink: 0;
  color: var(--White, #fff);
  text-align: center;
  font-family: Urbanist;
  font-size: 21.512px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 6.91px;
  background: #c356b3;
}

.editprofileImage {
  width: 163.371px;
  height: 163.71px;
}

.profileImage {
  /* width: 202px;
height: 202px; */
  width: 164px;
  height: 164px;
  border-radius: 14px;
  border: 1px solid #e8ecf4;
  background: #f7f8f9;
}

.profileImageSize {
  width: 67px;
  height: 80px;
}

.profileText1 {
  color: #8391a1;
  font-family: Urbanist;
  font-size: 22.372px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 125%; */
  /* 27.965px */
}

input[type="radio"]:checked {
  background-color: var(--bs-mainColor);
  border-color: var(--bs-mainColor);
}

.profileText2 {
  padding-left: 20px;
  color: #000;
  font-family: Urbanist;
  font-size: 22.372px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 125%; */
  /* 27.965px */
}

.showgraph {
  width: 142px;
  height: 44px;
  border-radius: 6px;
  background: #00b6b6;
  color: #fff;
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 700;
}

.modal-90w {
  max-width: none !important;
  width: 90%;
}

.menuBtn {
  border-radius: 2px;
  background: #00b6b6;
  color: var(--White);
}

.menu p {
  color: #000;
  font-family: Urbanist;
  font-size: 30px;
  font-weight: 500;
  line-height: 19px;
  /* 63.333% */
  text-transform: capitalize;
}

.dailyMenuIcon {
  width: 551px;
  height: 370px;
}

.menuST {
  color: #000;
  font-family: Urbanist;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 19px;  */
  text-transform: capitalize;
}

.menuPlanDiv {
  border-radius: 8px;
  border: 1px solid #00b6b6;
  background: rgba(0, 182, 182, 0.04);
  color: #1e232c;
  font-family: Urbanist;
  font-size: 15px;
  font-weight: 500;
}

.menu ul.a {
  list-style-type: circle;
}

/* .menu li {
  color: #4f0744;
} */

/* .menu ul {
  list-style: none;
} */

/* .menu ul li::before {
  content: "\2022";
  color: #00B6B6;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
} */

.downloadBtn {
  color: #fff;
  font-family: Urbanist;
  font-size: 21px;
  font-weight: 600;
  line-height: 125%;
  /* 26.25px */
  background-color: #c356b3;
  border-radius: 5px;
  background: #c356b3;
}

.graphText1 {
  color: #858585;
  font-family: Urbanist;
  font-size: 22px;
}

.graphText2 {
  color: #000;
  font-family: Urbanist;
  font-size: 28px;
  font-weight: 600;
}

.ctmInput .form-control:focus {
  background-color: #f7f8f9;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none !important;
}

.verify-code input {
  width: 55px;
  height: 55px;
  text-align: center;
}

.input-main-auth input {
  border-radius: 10px;
  border: 1.249px solid #bebebe;
  /* width: 100%; */
  padding: 14px 20px;
  outline: none;
  margin-top: 5px;
}

td,
th {
  text-wrap: nowrap;
}

@media only screen and (max-width: 1560px) and (min-width: 991px) {
  .sidebar-menu-list li a {
    text-decoration: none;
    margin: 0;
    font-size: 16px;
    font-style: normal;
    color: #5b5b5b;
    font-weight: 600;
    font-family: "Urbanist";
  }
}

.btnDisable {
  opacity: 0.5;
}

.one-line-text {
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Optional: Adds an ellipsis (...) if the text overflows */
}

.userHead {
  color: #2d393c;
  font-family: Urbanist;
  font-size: 16.122px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 24.182px */
}

.abc::after {
  content: " ";
  background-color: #000;
  border: 1px solid #000;
  background: #fff;
}

.sidebar-image-main {
  width: 20px;
}

.sidebar-image-main svg {
  width: 100%;
  height: 100%;
}

.back-button {
  background-color: var(--bs-secondColor);
  font-size: 13.97px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  border-radius: 5px;
  border: 0;
  padding: 10px 15px;
  display: flex;
  align-items: center;
}

.dropdown-menu {
  margin-top: 5px;
  width: 100%;
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 15px;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.user-chat-box {
  cursor: pointer;
}

.user-chat-box img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.user-chat-box h5 {
  font-size: 16px;
  font-weight: 600;
  color: #2d393c;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.user-chat-box span {
  color: #85908c;
  font-size: 14.45px;
  font-weight: 500;
  white-space: nowrap;
}

.user-chat-box p {
  font-size: 14.45px;
  font-weight: 500;
  color: #85908c;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* .sidebar-menu-list li a.active .sidebar-image-main img {
  filter: brightness(5.5);
} */

.type-msg {
  align-items: center;
  border: 1px solid #b8b8b8;
  border-radius: 7.406px;
  display: flex;
  gap: 10px;
  padding: 10px;
}

.type-msg input {
  border: none;
  outline: none;
  width: 100%;
}

.type-msg button {
  background-color: initial !important;
  border: none;
  box-shadow: none;
  padding: 0;
}

.type-msg button img {
  width: 20px;
}

.notify-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.borderMsg {
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.chat-area {
  height: calc(100vh - 235px);
  overflow: auto;
}

.chat-area ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.chat-area ul li {
  max-width: 400px;
}

.receiver-msg img {
  border-radius: 50%;
  height: 33px;
  object-fit: cover;
  width: 33px;
}

.receiver-msg p:first-child {
  margin-top: 0;
}

.receiver-msg p,
.send-msg p {
  font-size: 13.099px;
  font-weight: 500;
  margin-top: 10px;
  padding: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.receiver-msg p {
  background-color: #e8e8e8;
  border-radius: 0 10px 10px 10px;
  color: #5a5a5a;
}

.msg-time {
  color: #717171;
  font-size: 11.042px;
  font-weight: 400;
}

.msg-time {
  color: #717171;
  font-size: 11.042px;
  font-weight: 400;
}

.send-msg-text p {
  margin-left: auto;
}

.send-msg p {
  background: #00b6b6;
  border-radius: 10px 0 10px 10px;
  color: #fff;
}

.bgMsg {
  background-color: #61cbcb;
}

.bgMsg::-webkit-scrollbar {
  /* background-color: white;
  width: 4px; */
  display: none;
}

/* .bgMsg::-webkit-scrollbar-thumb {
  background-color: gray;
} */

.msgView input:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.channel .channelIcon {
  height: 20px;
  width: 20px;
  margin-left: 5px;
}

.user-chat-box .channelUser {
  border-radius: 50%;
  width: 37px;
  height: 37px;
  object-fit: cover;
}

.updateIcon {
  width: 40px;
  height: 40px;
  border-radius: 7px;
}

/* .image-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
  } */

.preview-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.image-preview {
  position: relative;
}

.image-preview1 {
  position: relative;
}

.image-preview img {
  width: 159px;
  height: 150px;
}

.image-preview1 img {
  width: 176px;
  height: 160px;
  object-fit: cover;
}

.image-preview1 video {
  width: 179px;
  height: 160px;
  object-fit: cover;
}

.image-preview button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  /* padding: 5px; */
  cursor: pointer;
  border-radius: 50%;
}

.image-preview button:hover {
  background-color: darkred;
}

.image-preview1 button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  /* padding: 5px; */
  cursor: pointer;
  border-radius: 50%;
}

.image-preview1 button:hover {
  background-color: darkred;
}

button:focus-visible {
  outline: none;
}

.star {
  color: #dc3545;
}

.ctmInput input::placeholder {
  color: #8391a1;
}

.ctmInput textarea::placeholder {
  color: #8391a1;
}

.text-black {
  color: #000;
}

@media only screen and (max-width: 1810px) and (min-width: 575px) {
  .thirdWeekHeading {
    width: 70%;
  }

  .lectureDescription {
    width: 60%;
  }

  .lectureView {
    height: 300px !important;
  }
}

@media only screen and (max-width: 410px) {
  .thirdWeekHeading {
    width: 70%;
  }

  .lectureDescription {
    width: 55%;
  }
}

@media only screen and (max-width: 788px) {
  .lectureView {
    height: 275px !important;
  }

  .info-modal .modal-title {
    font-size: 25px;
  }

  .s-img {
    width: 80%;
  }

  .modal-main .btn-close {
    top: 42px;
    right: 30px;
  }
}

@media only screen and (max-width: 320px) {
  .thirdWeekHeading {
    width: 45%;
  }
}

.fullTextShow {
  word-wrap: break-word;
  white-space: pre-wrap;
}

.weekIconSize {
  width: 50px;
  height: 50px;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: var(--ck-color-base-border);
  min-height: 200px;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: var(--ck-color-base-border);
  min-height: 200px;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  border-radius: var(--ck-border-radius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  min-height: 200px;
}

.profile-user {
  height: 40.64px;
  width: 40.38px;
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

.back-login {
  color: var(--bs-mainColor);
  font-weight: 700;
}

.back-login:hover {
  color: var(--bs-mainColor);
  font-weight: 800;
}

input:focus-visible {
  outline: none;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
  opacity: 1;
}

.form-control:focus {
  color: #212529;
  outline: 0;
  box-shadow: none;
}

.explore-head {
  font-family: Urbanist;
  font-size: 22px;
  font-weight: 700;
  color: #000000;

}

.explore-description {
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}

.for-you-img {
  width: 138px;
  height: 101px;
}

.review-tbl-img {
  width: 166px;
  height: 94px;
}

.w-15 {
  width: 15% !important;
}

.recipes-img {
  width: 166px;
  height: 94px;
}

.ctm-width {
  max-width: 450px;
  word-wrap: break-word;
  white-space: normal;
}

.ctm-drop .dropdown-toggle::after {
  display: inline-block;
  margin-left: -0.99rem !important;
  vertical-align: 0px !important;
  content: "";
  border-top: 0px !important;
  border-right: 0px;
  border-bottom: 0px;
  border-left: 0px !important;
}

.home-preview {
  position: relative;
}

.home-preview img {
  border-radius: 10px;
  width: 124px;
  height: 100px;
}

.home-preview button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  /* padding: 5px; */
  cursor: pointer;
  border-radius: 50%;
}

.home-preview button:hover {
  background-color: darkred;
}

.home-image {
  border-radius: 10px;
  width: 121px;
  height: 100px;
}

.help-label {
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 700;
  line-height: 2;
}

.ctm-link a {
  color: #555;
  text-decoration: none;
}

.ctm-link a:hover {
  color: black
}

.subTypeImage {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}

.custom-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 369px;
}

.table-view-image {
  width: 100%;
  height: 163.71px;
}

.trackingTitle {
  width: 66%;
  line-height: normal;
  max-height: 100%;
  color: #fff;
  font-size: 23px;
  font-weight: 700;
  line-height: 35px;
}

.subCategoryTitle {
  width: 80%;
  margin-left: 8px;
  font-family: "Urbanist";
  font-size: 19px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

/* .delete-title{
  font-size: 32px !important;
} */

/* help and support page start */

.help-support h5 {
  font-size: 26px;
  margin-bottom: 0;
  color: #ab4d9d;
}

/* help and support page  end*/


/* Faq page design start */
.faq-box {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #555;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-box .image-box {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.faq-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.faq-box h3 {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  text-align: start;
  margin: 0px;
}

.term-condition h5 {
  font-size: 26px;
  margin-bottom: 0;
  color: #ab4d9d;
}

.term-condition .term-details h6 {
  font-size: 18px;
  color: #000;
  margin-bottom: 10px;
  font-weight: 600;
}

.term-condition .term-details p {
  font-size: 17px;
  color: #5b5b5b;
  margin-bottom: 0;
  font-weight: 500;
}

.term-condition-modal textarea,
.term-condition-modal input {
  font-size: 15px;
}

.term-condition-modal label {
  margin-bottom: 6px;
  margin-top: 20px;
}

.term-condition-modal .bg-main {
  background-color: #ab4d9d;
  color: #fff;
  padding: 6px 12px;
  border-radius: 4px;
}

.term-condition-modal .add-term {
  background-color: #00b6b6;
  color: #fff;
  border: 0;
  padding: 6px 12px;
  border-radius: 4px;
  margin-top: 10px;
}

/* .messages-container {
  height: calc(100vh - 50px);
  overflow-y: auto;
  position: sticky;
  top: 0;
  padding-bottom: 45px;
} */

.card {
  margin-bottom: 10px;
}

/* Optional: Add styling for the scrollbar */
.messages-container::-webkit-scrollbar {
  width: 8px;
}

.messages-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.messages-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.replyCount {
  background-color: #f0f0f0;
  /* Light background color */
  color: #2db656;
  /* Dark text color */
  border-radius: 12px;
  /* Rounded corners */
  padding: 2px 8px;
  /* Padding around the text */
  font-weight: bold;
  /* Make the text bold */
  font-size: 14px;
  /* Adjust the font size */
  font-family: "Urbanist";
  cursor: pointer;
}

.replymsgView {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 49px;
  background-color: #61cbcb;
  right: 0;
  left: 0;
  /* Set your desired background color */
  padding: 10px;
  /* Adjust padding as needed */
  
}

.replymsgView input:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

/* .set-chip {
  position: relative;
    bottom: 10px;
    left: 930px;
} */
.reply-mess {
  position: relative;
  top: 23px;
}

td {
  max-width: 300px;
  width: auto;
  text-wrap: wrap;
  word-wrap: break-word;
}

.two-line_dots {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

/* Faq page design end */

.chat-area-box {
  height: calc(100vh - 315px);
  overflow: auto;
}

.chat-active {
  background-color: #c8f9f9;
}

.mess-cursor {
  cursor: pointer;
}

.rem-img-icon {
  position: relative;
  top: -23px;
  right: 18px;
  cursor: pointer;
  color: red;
  font-weight: 'bold';
  width: 20px;
}

.community-modal {
  width: 100%;
  height: 500px;
  object-fit: contain;
}

.images-wrapper {
  margin-top: 10px;
  flex-wrap: wrap;
}

.images-wrapper::-webkit-scrollbar {
  width: 5px;
}

.images-wrapper::-webkit-scrollbar-track {
  background-color: white;
}

.images-wrapper::-webkit-scrollbar-thumb {
  background-color: gray;
}

.images-wrapper .msg-images {
  position: relative;
  border: 1px solid #e1e1e1;
  padding: 4px 14px 4px 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 98px;
}

.images-wrapper .msg-images img {
  object-position: top;
}

.images-wrapper .msg-images .rem-img-icon {
  position: unset;
  width: unset;
}

.images-wrapper .msg-images .rem-img-icon img {
  width: 12px;
  height: 12px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.outline-0 {
  outline: 0;
}

.user-list-dropdown {
  background-color: white;
  padding: 15px 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  height: 200px;
  overflow: auto;
  position: absolute;
  left: 0;
  bottom: 100%;
  min-width: 200px;
}

.user-list-dropdown::-webkit-scrollbar {
  width: 4px;
  background-color: rgb(218, 218, 218);
}

.user-list-dropdown::-webkit-scrollbar-thumb {
  background-color: gray;
}

.user-list-dropdown .user-list-item:hover {
  background-color: rgb(228, 228, 228);
}

.user-list-dropdown .user-list-item {
  cursor: pointer;
  transition: all 0.2s;
  padding: 4px 10px;
}

.message-container {
  white-space: pre-wrap;
  /* Preserves whitespace and newlines */
  word-wrap: break-word;
  /* Breaks long words to prevent overflow */
}

.tagged-user {
  color: #007bff;
  /* Highlight color for tagged users */
  font-weight: bold;
  /* Optional: make tagged users bold */
}


.max-width-100 {
  max-width: 100% !important;
}

pre {
  font-family: "Urbanist" !important;
}

.custom-textarea {
  min-height: 1.5em;
  /* Adjust based on font size */
  resize: vertical;
  /* Allow vertical resizing */

}

.textarea-container .resize-controls {
  display: none;
  /* Hides the minimize/maximize icons */
  display: none; /* Hides the minimize/maximize icons */
}

.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 15px auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4caf50;

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: "";
      height: 100px;
      position: absolute;
      background: #ffffff;
      transform: rotate(-45deg);
    }

    .icon-line {
      height: 5px;
      background-color: #4caf50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, 0.5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #ffffff;
    }
  }
}

.btnGrey {
  background-color: grey !important;
  pointer-events: none;
}