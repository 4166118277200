/* Base toast style */
.Toastify__toast {
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
  background: #fff;
  border: 1px solid #f3a4e7;
  border-left: 4px solid #c356b3;
  padding: 16px;
  margin: 8px;
  color: #c356b3; /* Default text color set to blue */
}

/* Specific styles for success, info, error, and warning toasts */
.Toastify__toast--success,
.Toastify__toast--info,
.Toastify__toast--error,
.Toastify__toast--warning {
  border-left-color: #c356b3 !important; /* Blue left border for all toast types */
}

.Toastify__toast--info .Toastify__close-button,
.Toastify__toast--info .Toastify__toast-icon,
.Toastify__toast--success .Toastify__close-button,
.Toastify__toast--success .Toastify__toast-icon,
.Toastify__toast--error .Toastify__close-button,
.Toastify__toast--error .Toastify__toast-icon,
.Toastify__toast--warning .Toastify__close-button,
.Toastify__toast--warning .Toastify__toast-icon {
  color: #c356b3 !important; /* Blue color for close button and icon for all toast types */
}

/* Progress bar */
.Toastify__progress-bar {
  background: #c356b3 !important; /* Blue progress bar */
  height: 4px;
  border-radius: 15px;
}

/* Close button */
.Toastify__close-button {
  color: #c356b3; /* Blue color for the close button */
}

.Toastify__close-button > svg {
  fill: #c356b3;
  height: 16px;
  width: 14px;
}

/* Toast icon */
.Toastify__toast-icon {
  color: #c356b3; /* Blue color for the toast icon */
}

/* Override icon colors for success and error toasts */
.Toastify__toast--success .Toastify__toast-icon {
  color: #c356b3 !important; /* Blue color for success icon */
}

.Toastify__toast--error .Toastify__toast-icon {
  color: #c356b3 !important; /* Blue color for error icon */
}

/* Hide the default toast icons */
.Toastify__toast--success .Toastify__toast-icon,
.Toastify__toast--error .Toastify__toast-icon {
  display: none;
}

/* Add custom icon for success toast */
.Toastify__toast--success {
  background-image: url("../images/success.svg"); /* Path to your custom success icon */
  background-repeat: no-repeat;
  background-position: 20px center; /* Adjust as needed */
  padding-left: 50px; /* Adjust padding to accommodate the icon */
}

/* Add custom icon for error toast */
.Toastify__toast--error {
  background-image: url("../images/Error.svg"); /* Path to your custom error icon */
  background-repeat: no-repeat;
  background-position: 20px center; /* Adjust as needed */
  padding-left: 50px; /* Adjust padding to accommodate the icon */
}
