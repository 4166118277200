@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../../../public/font/Urbanist-Thin.woff2) format("woff2");
}

@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../../../public/font/Urbanist-ExtraLight.woff2) format("woff2");
}

@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../../../public/font/Urbanist-Light.woff2) format("woff2");
}

@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../../../public/font/Urbanist-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../../../public/font/Urbanist-Medium.woff2) format("woff2");
}

@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../../../public/font/Urbanist-SemiBold.woff2) format("woff2");
}

@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../../../public/font/Urbanist-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../../../public/font/Urbanist-ExtraBold.woff2) format("woff2");
}

@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../../../public/font/Urbanist-Black.woff2) format("woff2");
}