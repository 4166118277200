.loader-css {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: #0000006b;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.loader-white {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;    
    z-index: 9999;
    background: #0000006b;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    border-radius: 7px;
}


.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #c356b3;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    background: #c356b3;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    background: #00B6B6;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    background: #c356b3;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    background: #00B6B6;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}